export const collaboratorListItems = [
    'Regular',
    'Best Online Homeopathic Treatment for quick and lasting results.',
    'Self-Heal Body Analysis by reflexology (On request @ Rs 3000)',
    'One Acute Disease consultation (Online) during the plan duration.',
    '24/7 online consultation.',
    'Support through WhatsApp/ E- mail/ Website.',
    'Regular follow up of treatment.',
    'Expert advice from Post graduate Experts with rich Experience.',
    'Video Chat / Voice Call with Doctors.',
]

export const createrListItems = [
    'Most Popular',
    'Best Online Homeopathic Treatment for quick and lasting results.',
    'Free Self Heal Body Analysis by reflexology worth Rs 3000',
    'Unlimited Acute Disease consultations (Online) during the plan',
    '24/7 online consultation.',
    'Support through WhatsApp/ E- mail/ Website.',
    'Regular follow up of treatment.',
    'Expert advice from Post graduate Experts with rich Experience.',
    'Video Chat / Voice Call with Doctors.',
    
]

export const ezCreatorList = [
    'Most Economical',
    'Best Online Homeopathic Treatment for quick and lasting results.',
    'Free Self Heal Body Analysis by reflexology worth Rs 3000',
    'Unlimited Acute Disease consultations (Online) during the plan',
    '24/7 online consultation.',
    'Support through WhatsApp/ E- mail/ Website.',
    'Regular follow up of treatment.',
    'Expert advice from Post graduate Experts with rich Experience.',
    'Video Chat / Voice Call with Doctors.',
    
]
